import { Link, useParams } from "react-router-dom";
import Feature from "../components/app/Feature";
import FAQ from "../components/app/FAQ";
import { useEffect } from "react";
import apps from "../data/apps";

const AppPage = () => {
  const { appId } = useParams();
  const { name, subtext, promoVideoId, clientId, features, faqs } = apps[appId];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section className="bg-primary-dark">
        <div className="container flex justify-between items-center py-24">
          <div className="w-[600px] flex flex-col gap-8">
            <h2 className="text-white">{name}</h2>
            <p className="p2-b text-white">{subtext}</p>
            <div className="flex gap-10">
              <Link
                to={`https://auth.monday.com/oauth2/authorize?client_id=${clientId}&amp;response_type=install`}
                className="flex-1"
                target="_blank"
              >
                <img
                  alt="Add to monday.com"
                  height="60"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                />
              </Link>
              <Link
                to={`/${appId}/how-to-use`}
                className="p2-b flex-1 p-4 text-center text-white bg-primary rounded-lg"
              >
                How To Use
              </Link>
            </div>
          </div>
          <div className="w-2/5">
            <iframe
              width="500"
              height="280"
              src={`https://www.youtube.com/embed/${promoVideoId}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="rounded-xl"
            ></iframe>
          </div>
        </div>
      </section>

      <section>
        <div className="container flex flex-col gap-10 py-20">
          {features.map(({ name, text, image }, index) => (
            <Feature name={name} description={text} image={image} reverse={index % 2 !== 0} />
          ))}
        </div>
      </section>

      <section className="bg-secondary-light">
        <div className="container flex flex-col items-center gap-10 py-10">
          <div>
            <h3 className="text-primary-dark">FAQs</h3>
          </div>
          <div className="w-full flex flex-col gap-5">
            {faqs.map(({ question, answer }) => (
              <FAQ question={question} answer={answer} />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default AppPage;
